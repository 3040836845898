import { useEffect } from "react";
import getWindow from "../utils/window";

export default function useEventBus(eventName, callback, dependencies) {
  useEffect(() => {
    if (!getWindow()) {
      return;
    }
    const listener = getWindow().asos.eventBus.addListener(eventName, callback);

    return () => {
      listener?.remove();
    };
  }, dependencies);
}
