import { useEffect, useState } from "react";
import getStockPrice from "../../api/stockPriceApi";
import { variantMapper } from "../../../mappers/variant";
import { useRuntimeConstants } from "../runtimeConstants/RuntimeConstantsContext";
import { productIsInStock } from "../../utils/product";
import getWindow from "../../utils/window";
import { ERROR_TYPE, logError } from "../../utils/newRelicLogger";
import getRestockingSoonDetails from "./getRestockingSoonDetails";

const filterInvalidVariants = (products) => {
  return products.map((product) => ({
    ...product,
    variants: product.variants.filter(({ price }) => !!price),
  }));
};

export const usePriceAndProductData = ({ retry, retryStockPriceCall }) => {
  const { product, stockPriceApiUrl } = useRuntimeConstants();
  const [products, setProducts] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    getStockPrice(product, retry)
      .then((stockPriceProducts) => {
        if (!stockPriceProducts) {
          return;
        }

        const hasMultiProducts = !!product.products;

        const productList = hasMultiProducts
          ? [...product.products]
          : [{ ...product }];

        const mappedProducts = productList.map((productItem) => {
          const stockPriceProduct = stockPriceProducts.find(
            ({ productId }) => productItem.id === productId
          );

          const matchedVariants = productItem.variants.map((serverVariant) => {
            const stockPriceVariant = stockPriceProduct?.variants.find(
              ({ id }) => serverVariant.variantId === id
            );

            if (!stockPriceVariant) {
              return serverVariant;
            }

            const stockPriceVariantHasPrice = !!stockPriceVariant.price;
            const mappedStockPriceVariant = variantMapper(
              stockPriceVariant,
              stockPriceVariantHasPrice
            );

            const { price, isInStock, seller, source, isLowInStock } =
              mappedStockPriceVariant;

            return {
              ...serverVariant,
              price,
              isInStock,
              seller,
              source,
              isLowInStock,
            };
          });

          const stockStatus =
            matchedVariants[0].isInStock !== undefined
              ? { isInStock: productIsInStock(stockPriceProduct) }
              : {};

          const price = stockPriceProduct?.productPrice || null;

          const restockingSoonDetails = getRestockingSoonDetails({
            stockPriceProduct,
            stockStatus,
          });

          return {
            ...productItem,
            ...stockStatus,
            restockingSoonDetails,
            price,
            variants: [...matchedVariants],
          };
        });

        const mappedProductsWithFilteredVariants =
          filterInvalidVariants(mappedProducts);

        setProducts(
          hasMultiProducts
            ? {
                ...product,
                products: mappedProductsWithFilteredVariants,
              }
            : mappedProductsWithFilteredVariants[0]
        );
        setHasError(false);

        getWindow().asos.eventBus.emit("productAndPriceData", {
          ...product,
          products: mappedProductsWithFilteredVariants,
        });
      })
      .catch((error) => {
        setHasError(true);
        logError(ERROR_TYPE.StockPriceError, {
          action: "getStockPrice",
          caller: "stockPrice",
          apiName: "stockPrice",
          url: stockPriceApiUrl,
          message: error.message || error.statusText,
          status: error.status,
          response: error.data,
          responseHeaders: error.headers,
        });
      })
      .finally(() => {
        retryStockPriceCall(false);
      });
  }, [product, retry, retryStockPriceCall, stockPriceApiUrl]);

  return { products, hasError };
};
