import getRequest from "../utils/requests";
import { logError, ERROR_TYPE } from "../utils/newRelicLogger";
import { queryObjectToString } from "../utils/url";
import { GET } from "../constants";

function removeAuthorizationHeader(headers) {
  const redactedHeaders = { ...headers };
  delete redactedHeaders["authorization"];
  return redactedHeaders;
}

export default async function makeRequest({
  params,
  url,
  timeout,
  headers,
  requestType,
  apiName,
  caller,
  mapData,
  body,
  handleErrorReturnValue,
  method = GET,
}) {
  return getRequest()
    [method]({
      url,
      params,
      timeout,
      headers,
      body,
      additionalLoggingParams: {
        requestType,
      },
    })
    .then(({ data }) => {
      if (mapData) {
        return mapData(data);
      }
      return data;
    })
    .catch((error) => {
      logError(ERROR_TYPE.AjaxError, {
        apiName,
        caller,
        requestUrl: `${url}${params ? "?" + queryObjectToString(params) : ""}`,
        message: error.message,
        status: error.response?.status,
        response: error.response?.data,
        requestHeaders: removeAuthorizationHeader(error.config?.headers),
        responseHeaders: error.response?.headers,
      });
      if (handleErrorReturnValue) {
        return handleErrorReturnValue(error);
      }
    });
}
