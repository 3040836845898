import ReactDOM from "react-dom";
import { loadableReady } from "@loadable/component";
import Providers from "./Providers";
import { addPageAttributesToReporting } from "./utils/addPageAttributesToReporting";
import getRuntimeConstants from "./runtimeConstants";
import getWindow from "./utils/window";

getWindow().dispatchEvent(new Event("PDPApplicationStart"));
getWindow().asos.pdp.appStarted = true;

loadableReady(() => {
  ReactDOM.hydrate(
    <Providers runtimeConstants={getRuntimeConstants()} />,
    document.getElementById("pdp-react-critical-app")
  );
});

addPageAttributesToReporting();
