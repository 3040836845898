import { useState } from "react";

import {
  buildSessionStorageEventName,
  getSessionStorageItem,
} from "../utils/storage";
import useEventBus from "./useEventBus";

export default function useSessionStorage(key) {
  const [value, setValue] = useState(() => getSessionStorageItem(key));
  useEventBus(buildSessionStorageEventName(key), setValue, [key]);

  return value;
}
