import { useEffect } from "react";
import { usePreviousBackInStock } from "../context/backInStock/BackInStockContext";
import useBackInStock from "./useBackInStock";

export default function useSetNotificationsOnReturnFromLogin() {
  const { previousBackInStockVariantId } = usePreviousBackInStock();
  const {
    hasNotification,
    addVariantToNotifications,
    removeVariantFromNotifications,
  } = useBackInStock();
  useEffect(() => {
    if (previousBackInStockVariantId) {
      if (hasNotification(previousBackInStockVariantId)) {
        removeVariantFromNotifications({
          variantId: previousBackInStockVariantId,
          suppressLogin: true,
        });
      } else {
        addVariantToNotifications({
          variantId: previousBackInStockVariantId,
          suppressLogin: true,
        });
      }
    }
  }, [previousBackInStockVariantId]);
}
