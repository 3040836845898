import { setEvents } from "../events";

let backInStockResolve;

export const backInStockPromise = new Promise(
  (resolve) => (backInStockResolve = resolve)
);

export const ignoreBackInStockAnalytics = () => backInStockResolve();

export const setBackInStockAnalytics = async () => {
  setEvents({ events: ["event225"], trackingName: "postPageLoad" });
  backInStockResolve();
};
