import { RuntimeConstantsProvider } from "./context/runtimeConstants/RuntimeConstantsContext";
import { SaveForLaterProvider } from "./context/saveForLater/SaveForLaterContext";
import { ViewportProvider } from "./context/viewport/ViewportContext";
import { LiveRegionProvider } from "./context/liveRegion/LiveRegionContext";
import { FeaturesReadyProvider } from "./context/featuresReady/FeaturesReadyContext";
import { IsInStockContextProvider } from "./context/isInStock/IsInStockContext";
import { StockPriceProvider } from "./context/stockPrice/StockPriceContext";
import { PrivacyContextProvider } from "./context/privacy/PrivacyContext";
import { LooksProductsProvider } from "./context/looks/LooksProductsContext";
import CriticalApp from "./CriticalApp";
import PropTypes from "prop-types";
import { BackInStockProvider } from "./context/backInStock/BackInStockContext";
import { SelectedLooksProductProvider } from "./context/looks/SelectedLooksProductContext";

const Providers = ({ runtimeConstants }) => {
  return (
    <PrivacyContextProvider>
      <ViewportProvider>
        <RuntimeConstantsProvider runtimeConstants={runtimeConstants}>
          <StockPriceProvider>
            <IsInStockContextProvider>
              <SaveForLaterProvider>
                <LiveRegionProvider>
                  <FeaturesReadyProvider>
                    <BackInStockProvider>
                      <LooksProductsProvider>
                        <SelectedLooksProductProvider>
                          <CriticalApp />
                        </SelectedLooksProductProvider>
                      </LooksProductsProvider>
                    </BackInStockProvider>
                  </FeaturesReadyProvider>
                </LiveRegionProvider>
              </SaveForLaterProvider>
            </IsInStockContextProvider>
          </StockPriceProvider>
        </RuntimeConstantsProvider>
      </ViewportProvider>
    </PrivacyContextProvider>
  );
};

export default Providers;

Providers.propTypes = {
  runtimeConstants: PropTypes.object.isRequired,
};
