import { buildCurrentAriaLabel } from "./currentPrice";
import { buildLowestPriceInLast30DaysAriaLabel } from "./lowestPriceInLast30Days";

export function buildOutletPrice({ rrp, translations }) {
  if (!rrp?.text) {
    return "";
  }

  return `${translations("pdp_price_rrp")} ${rrp.text}`;
}

export function buildOutletAriaLabel({
  rrp,
  current,
  translations,
  showFromLabel,
  showWasNowLabel,
  showLowestPriceInLast30Days,
  lowestPriceInLast30DaysText,
  lowestPriceInLast30DaysValue,
  lowestPriceInLast30DaysPercentage,
}) {
  if (!rrp?.text || !current?.text) {
    return "";
  }

  if (showWasNowLabel) {
    const buildWasNowAriaLabel = () => {
      const currentPriceAriaLabel = buildCurrentAriaLabel({
        current,
        translations,
        showFromLabel,
        showNowLabel: true,
      });

      const lowestPriceInLast30DaysAriaLabel =
        showLowestPriceInLast30Days &&
        buildLowestPriceInLast30DaysAriaLabel({
          translations,
          lowestPriceInLast30DaysText,
          lowestPriceInLast30DaysValue,
          lowestPriceInLast30DaysPercentage,
        });

      const rrpAriaLabel = `${translations("pdp_price_rrp_longform")} ${
        rrp.text
      }`;

      return `${currentPriceAriaLabel}. ${
        lowestPriceInLast30DaysAriaLabel
          ? `${lowestPriceInLast30DaysAriaLabel}. `
          : ""
      }${rrpAriaLabel}.`;
    };

    return buildWasNowAriaLabel();
  }

  return `${translations("pdp_price_rrp_longform")} ${rrp.text}.${
    showFromLabel ? ` ${translations("pdp_misc_from")}` : ""
  } ${translations("pdp_price_sale")} ${current.text}.`;
}
