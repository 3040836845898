import { createContext, useContext } from "react";
import { PropTypes } from "prop-types";
import { useIsInStock } from "./useIsInStock";

const IsInStockContext = createContext(null);

export const IsInStockContextProvider = ({ children }) => {
  const isInStock = useIsInStock();

  return (
    <IsInStockContext.Provider
      value={{ isStockStateReady: typeof isInStock === "boolean", isInStock }}
    >
      {children}
    </IsInStockContext.Provider>
  );
};

IsInStockContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useIsInStockContext = () => useContext(IsInStockContext);
