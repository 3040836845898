import getWindow from "./window";

const getPreviousValue = (key) => {
  const s = getWindow().s;

  const value = s.getPreviousValue(null, key);

  return value === "no value" || value === "" ? null : value;
};

export default getPreviousValue;
