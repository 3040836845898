import { getLocalStorageItem, setLocalStorageItem } from "../../utils/storage";
import { FACET_PRODUCT_SIZE_SELECTION } from "../../constants";
import getRuntimeConstants from "../../runtimeConstants";

export function getVariantForFacet({ variants }) {
  const {
    product: { facetGroup },
  } = getRuntimeConstants();
  const preSelectedFacetSize = getLocalStorageItem(
    FACET_PRODUCT_SIZE_SELECTION
  );

  if (
    facetGroup &&
    preSelectedFacetSize &&
    facetGroup.facetGroupId === preSelectedFacetSize.facetGroupId
  ) {
    return variants.find(
      ({ size, isInStock }) => size === preSelectedFacetSize.size && isInStock
    );
  }
}

export function setVariantForFacet({ variant }) {
  const {
    product: { facetGroup },
  } = getRuntimeConstants();

  if (facetGroup) {
    setLocalStorageItem(FACET_PRODUCT_SIZE_SELECTION, {
      facetGroupId: facetGroup.facetGroupId,
      size: variant.size,
    });
  }
}
