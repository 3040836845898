import { useCallback, useEffect, useRef } from "react";
import useSessionStorage from "./useSessionStorage";
import {
  getBackInStockVariantIds,
  addVariantToNotifications,
  removeVariantFromNotifications,
} from "../api/backInStockApi";
import { BACK_IN_STOCK_VARIANT_IDS_SESSION_KEY } from "../constants";
import { setSessionStorageItem } from "../utils/storage";

const setBackInStockSessionStorage = (variantIds) =>
  setSessionStorageItem(BACK_IN_STOCK_VARIANT_IDS_SESSION_KEY, variantIds);

export default function useBackInStock() {
  const backInStockVariantIds = useSessionStorage(
    BACK_IN_STOCK_VARIANT_IDS_SESSION_KEY
  );
  const getBackInStockVariantIdsPromise = useRef();

  const hasNotification = useCallback(
    (variantId) => {
      if (!variantId) {
        return false;
      }

      return !!backInStockVariantIds?.includes(variantId);
    },
    [backInStockVariantIds]
  );

  useEffect(() => {
    if (Array.isArray(backInStockVariantIds)) {
      return;
    }

    getBackInStockVariantIdsPromise.current = getBackInStockVariantIds().then(
      (variantIds) => {
        if (variantIds) {
          setBackInStockSessionStorage(variantIds);
        }
        return variantIds;
      }
    );
  }, [backInStockVariantIds]);

  const addBackInStockVariant = useCallback(
    async (buttonActionArguments) => {
      let response = null;
      try {
        response = await addVariantToNotifications(buttonActionArguments);
      } catch (error) {
        // no op
      }
      const requestVariantIds = await getBackInStockVariantIdsPromise.current;
      if (response !== null) {
        const currentVariantIds = new Set([
          ...(requestVariantIds || []),
          ...(backInStockVariantIds || []),
        ]);
        setBackInStockSessionStorage([
          ...currentVariantIds,
          buttonActionArguments.variantId,
        ]);
      }
    },
    [backInStockVariantIds]
  );

  const removeBackInStockVariant = useCallback(
    (buttonActionArguments) => {
      removeVariantFromNotifications(buttonActionArguments).then((response) => {
        if (response !== null) {
          setBackInStockSessionStorage(
            backInStockVariantIds.filter(
              (currentId) => currentId !== buttonActionArguments.variantId
            )
          );
        }
      });
    },
    [backInStockVariantIds]
  );

  return {
    hasNotification,
    addVariantToNotifications: addBackInStockVariant,
    removeVariantFromNotifications: removeBackInStockVariant,
  };
}
