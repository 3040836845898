import getWindow from "../../utils/window";
import { setEvents } from "../events";

const PERFORMANCE_ADOBE_VARIABLES = {
  "pdp:start": { eVar: "eVar163", event: "event233" },
  "pdp:hero_image_displayed": { eVar: "eVar164", event: "event234" },
  "pdp:title_displayed": { eVar: "eVar165", event: "event235" },
  "pdp:stock_api_returned": { eVar: "eVar166", event: "event236" },
  "pdp:price_displayed": { eVar: "eVar167", event: "event237" },
  "pdp:size_selector_interactive": { eVar: "eVar168", event: "event238" },
  "pdp:add_to_bag_interactive": { eVar: "eVar169", event: "event239" },
  "pdp:save_for_later_interactive": { eVar: "eVar170", event: "event240" },
};

export function setComponentPerformance() {
  if (!getWindow().performance.getEntriesByType) {
    return;
  }

  const s = getWindow().s;
  const events = [];

  getWindow()
    .performance.getEntriesByType("measure")
    .filter(({ name }) =>
      Object.keys(PERFORMANCE_ADOBE_VARIABLES).includes(name)
    )
    .map(({ name, duration }) => {
      const { eVar, event } = PERFORMANCE_ADOBE_VARIABLES[name];
      const roundedDuration = Math.floor(duration);

      s[eVar] = roundedDuration;
      events.push(`${event}=${roundedDuration}`);
    });

  if (events.length) {
    setEvents({
      events,
      trackingName: "postPageLoad",
    });
  }
}
