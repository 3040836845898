import { PRODUCT_COLOUR_POSITIONS, SMALL_VIEWPORT } from "../../../constants";

export const isProductWithSupplierColours = (product) => {
  return (
    !!product?.facetGroup &&
    product.facetGroup.facets.some((facet) => facet.type === "SupplierColour")
  );
};

export const shouldShowFacets = ({ product, areFeaturesReady, getFeature }) => {
  if (!isProductWithSupplierColours(product)) {
    return false;
  }

  return isFacetFeatureEnabled({ getFeature, areFeaturesReady });
};

export const shouldShowFacetCarousel = ({
  viewportCategory,
  position,
  product,
  areFeaturesReady,
  getFeature,
}) =>
  viewportCategory === SMALL_VIEWPORT &&
  position === PRODUCT_COLOUR_POSITIONS.TOP &&
  shouldShowFacets({
    product,
    areFeaturesReady,
    getFeature,
  });

export const shouldShowFacetPalette = ({
  viewportCategory,
  product,
  position,
  areFeaturesReady,
  getFeature,
}) =>
  viewportCategory !== SMALL_VIEWPORT &&
  position === PRODUCT_COLOUR_POSITIONS.MIDDLE &&
  shouldShowFacets({
    product,
    areFeaturesReady,
    getFeature,
  });

export const shouldShowProductColour = ({
  product,
  colours,
  position,
  getFeature,
  areFeaturesReady,
}) =>
  colours.length === 1 &&
  position === PRODUCT_COLOUR_POSITIONS.MIDDLE &&
  !(
    !!product?.facetGroup &&
    isFacetFeatureEnabled({ getFeature, areFeaturesReady })
  );

const isFacetFeatureEnabled = ({ getFeature, areFeaturesReady }) => {
  if (areFeaturesReady) {
    const facetGroupFeature = getFeature("plp-pdp-facet-groups", null, {
      app: "pdp",
    });

    //An exception where we're using the variable "PDP: true" to decide whether to show the feature,
    // but we still need to call isEnabled for optimizely to know its been seen
    facetGroupFeature.isEnabled();

    return facetGroupFeature.getVariables()?.bucket !== "v2";
  }
  return false;
};
