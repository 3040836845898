import getConfig from "../config";

const getRequest = () => {
  const { appVersion: version } = getConfig();
  return window.asos.webRequest.configureRequest({
    client: {
      name: "asos-web-productpage",
      version,
    },
  });
};

export default getRequest;
