import getWindow from "../../utils/window";

export const getCommonLooksTrackingValues = ({ products }) => {
  const s = getWindow().s;
  const heroProduct = products.find(({ isHero }) => isHero);

  return {
    pageName: `look: ${heroProduct?.id}`,
    prop4: `${s.prop1}~${s.prop2}~${s.prop3}`,
    prop8: "buy the look",
    eVar60: "buy the look",
    eVar61: "buy the look",
  };
};
