import getConstants from "../runtimeConstants";
import getWindow from "./window";

export const ERROR_TYPE = {
  AjaxError: "Ajax Error",
  ParseError: "Parse Error",
  UserActionFailed: "User Action Failed",
  FitAssistantError: "Fit Assistant Error",
  SdkError: "SDK Error",
  EdgeError: "Edge Error",
  UnknownError: "Unknown Error",
  NonCriticalAppError: "Non Critical App Error",
  StockPriceError: "Stock Price Error",
};

export function logError(type = ERROR_TYPE.UnknownError, data) {
  const { appVersion, isMixAndMatch } = getConstants();

  if (getWindow().newrelic) {
    const errorData = {
      ...data,
      pageVersion: appVersion,
      pageUrl: getWindow().location.href,
      pageType: isMixAndMatch
        ? "ProductPage.MixAndMatch"
        : "ProductPage.SingleProduct",
      codebase: "React",
    };

    getWindow().newrelic.addPageAction(type, errorData);
  }
}
