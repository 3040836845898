import getWindow from "../utils/window";

let stockPriceResponse;

export default async function getStockPrice(product, retry) {
  if (product.isDiscontinued) {
    return [];
  }

  if (getWindow() && (!stockPriceResponse || retry)) {
    stockPriceResponse = getWindow().asos.pdp.getStockPrice(retry);
  }

  return stockPriceResponse;
}
