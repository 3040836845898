import { setEvents } from "../events";
import getWindow from "../../utils/window";

export const setPaymentPromoViewed = ({ providers }) => {
  const s = getWindow()?.s;

  if (providers.length && !s.eVar238) {
    setEvents({ events: ["event324"], trackingName: "pageLoad" });
    s.eVar238 = providers.join("|");
  }
};
