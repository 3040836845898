import { calculatePercentageDiscount } from "./calculatePercentageDiscount";
import {
  buildCurrentPrice,
  buildCurrentAriaLabel,
  buildMarkedDownPrice,
  buildMarkedDownAriaLabel,
  buildOutletPrice,
  buildOutletAriaLabel,
  buildLowestPriceInLast30DaysPrice,
  buildLowestPriceInLast30DaysPercentageDiscountLabel,
} from "./pricing";

export function isDiscounted({ isMarkedDown, isOutletPrice }) {
  return isMarkedDown || isOutletPrice;
}

export function getDiscountAmount(priceData) {
  if (isDiscounted(priceData)) {
    if (priceData.discountPercentage) {
      return priceData.discountPercentage;
    }
    return calculatePercentageDiscount(
      priceData.current.value,
      priceData.isOutletPrice ? priceData.rrp.value : priceData.previous.value
    );
  }
}

function getInStockVariants(variants) {
  return variants.filter(({ isInStock }) => isInStock);
}

function getOutOfStockVariants(variants) {
  return variants.filter((variant) => !variant.isInStock);
}

export function getLowestPriceInStockVariant({ variants }) {
  if (!variants.length) {
    return;
  }

  return getInStockVariants(variants).reduce(
    (lowestPriceVariant, variant) => {
      if (
        lowestPriceVariant.price?.current?.value > variant.price.current.value
      ) {
        return variant;
      }
      return lowestPriceVariant;
    },
    { price: { current: { value: Infinity } } }
  );
}

export function areAllInStockVariantsTheSamePrice({ variants }) {
  const firstVariantPriceValue = variants[0].price.current.value;

  return getInStockVariants(variants).every(
    ({
      price: {
        current: { value },
      },
    }) => value === firstVariantPriceValue
  );
}

export function areAllOOSVariantsTheSamePrice({ variants }) {
  const firstVariantPriceValue = variants[0].price.current.value;

  return getOutOfStockVariants(variants).every(
    ({
      price: {
        current: { value },
      },
    }) => value === firstVariantPriceValue
  );
}

export function getPrice({
  translations,
  priceData = {},
  showFromLabel = false,
  showWasNowLabel = false,
  showLowestPriceInLast30Days = false,
}) {
  const {
    current,
    previous,
    rrp,
    isMarkedDown,
    isOutletPrice,
    lowestPriceInLast30DaysValue,
    lowestPriceInLast30DaysText,
    lowestPriceInLast30DaysPercentage,
    discountPercentage,
  } = priceData;

  const price = {
    currentPriceText: buildCurrentPrice({
      current,
      translations,
      showFromLabel,
      showNowLabel: showWasNowLabel,
    }),
  };

  if (!isDiscounted({ isMarkedDown, isOutletPrice })) {
    price.priceAriaLabel = buildCurrentAriaLabel({
      current,
      translations,
      showFromLabel,
      showNowLabel: showWasNowLabel,
    });
    price.percentageDiscountLabel = "";

    return price;
  }

  price.percentageDiscountLabel = `-${getDiscountAmount({
    current,
    previous,
    rrp,
    isMarkedDown,
    isOutletPrice,
    discountPercentage,
  })}%`;

  price.previousPriceText = isOutletPrice
    ? buildOutletPrice({ rrp, translations })
    : buildMarkedDownPrice({
        previous,
        translations,
        showWasLabel: showWasNowLabel,
      });

  price.priceAriaLabel = isOutletPrice
    ? buildOutletAriaLabel({
        rrp,
        current,
        translations,
        showFromLabel,
        showWasNowLabel,
        showLowestPriceInLast30Days,
        lowestPriceInLast30DaysText,
        lowestPriceInLast30DaysValue,
        lowestPriceInLast30DaysPercentage,
      })
    : buildMarkedDownAriaLabel({
        previous,
        current,
        translations,
        showFromLabel,
        showWasLabel: showWasNowLabel,
        showLowestPriceInLast30Days,
        lowestPriceInLast30DaysText,
        lowestPriceInLast30DaysValue,
        lowestPriceInLast30DaysPercentage,
      });

  if (showLowestPriceInLast30Days) {
    price.lowestPriceInLast30DaysText = buildLowestPriceInLast30DaysPrice({
      translations,
      lowestPriceInLast30DaysValue,
      lowestPriceInLast30DaysText,
      lowestPriceInLast30DaysPercentage,
    });
    price.lowestPriceInLast30DaysPercentageDiscountLabel =
      buildLowestPriceInLast30DaysPercentageDiscountLabel(
        lowestPriceInLast30DaysPercentage
      );
  }

  return price;
}
