import getRuntimeConstants from "../runtimeConstants";
import getSeoPath from "asos-web-seo-path";

const getProductId = (hostAndPath) => {
  const splitOfPath = hostAndPath.match(/\/prd\/([0-9]+)/);
  if (splitOfPath) {
    return splitOfPath[1];
  }
};
const getVariantFromProductUsingVariantId = (product, variantId) =>
  product.variants.find(
    (variant) => variant.variantId === parseInt(variantId, 10)
  );
const getVariantFromProductUsingColourwayId = (product, colourwayId) =>
  shouldUseVariantFromColourWayId(colourwayId, product) &&
  product.variants.find((variant) => variant.colourWayId === colourwayId);
const productHasOneSizeOrLess = (product) =>
  product.isOneSize || product.isNoSize;
const shouldUseVariantFromColourWayId = (colourWayId, product) =>
  colourWayId &&
  product.totalNumberOfColours > 1 &&
  productHasOneSizeOrLess(product);

export function formatUrl(url) {
  if (typeof url !== "string") {
    return "";
  }

  if (url.substring(0, 4) === "http") {
    return url.replace("http:", "https:");
  }

  if (url.substring(0, 2) === "//") {
    return url.replace("//", "https://");
  }

  if (url.substring(0, 1) === "/") {
    return url;
  }

  return "https://" + url;
}

export function getProductUrl(brandName, productName, productId) {
  const { siteDomain, webContext } = getRuntimeConstants();

  if (!productId) {
    return siteDomain;
  }

  let path = "";

  if (brandName && productName && webContext?.browseLanguage) {
    path = `/${getSeoPath()(
      productName,
      brandName,
      webContext.browseLanguage
    )}`;
  }

  return `${siteDomain}${path}/prd/${productId}`;
}

export function getVariantFromUrl(product) {
  const { hostAndPath, urlParams } = getRuntimeConstants();
  const variantFromPath = getProductId(hostAndPath);

  return (
    getVariantFromProductUsingVariantId(product, variantFromPath) ||
    getVariantFromProductUsingVariantId(product, urlParams.variantid) ||
    getVariantFromProductUsingColourwayId(product, urlParams.colourwayid)
  );
}

export function queryObjectToString(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
