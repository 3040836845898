import makeRequest from "./makeRequest";
import getRuntimeConstants from "../runtimeConstants";
import {
  getAuthorisationHeader,
  getCustomerIsAuthenticated,
} from "../utils/identity";
import {
  BACK_IN_STOCK_API_TIMEOUT,
  BACK_IN_STOCK_BUTTON_ID,
  BACK_IN_STOCK_NOTIFY_MODAL_COPY_KEY,
  BACK_IN_STOCK_TURN_OFF_ALERT_MODAL_COPY_KEY,
  PUT,
  DELETE,
} from "../constants";
import getWindow from "../utils/window";

const BACK_IN_STOCK_DISABLE_NOTIFICATION_COPY_KEY =
  "back_in_stock_disable_confirmation";
const BACK_IN_STOCK_NOTIFICATION_COPY_KEY = "back_in_stock_confirmation";
const BACK_IN_STOCK_ERROR_COPY_KEY = "pdp_status_error_technical_difficulties";

const initiateLogin = async ({ variantId, productId, copyKey } = {}) => {
  getWindow().asos.eventBus.emit("loginModalOpen", {
    focusOnCloseElement: getWindow().document.getElementById(
      BACK_IN_STOCK_BUTTON_ID
    ),
    copyKey,
    selectedVariantId: variantId,
    selectedProductId: productId,
  });
};

const getApiData = async () => {
  const {
    webContext: { storeId },
  } = getRuntimeConstants();

  const Authorization = await getAuthorisationHeader();

  return {
    url: getBackInStockEndpoint(storeId),
    timeout: BACK_IN_STOCK_API_TIMEOUT,
    handleErrorReturnValue: (error) => {
      throw error;
    },
    headers: {
      Authorization,
    },
  };
};

const makeResponseHandler = (config) => () => {
  const { translations } = getRuntimeConstants();

  getWindow().asos.eventBus.emit("notificationBar", {
    type: config.type,
    copy: translations.t(config.copyKey),
    refocusId: BACK_IN_STOCK_BUTTON_ID,
  });
};

const handleError = makeResponseHandler({
  copyKey: BACK_IN_STOCK_ERROR_COPY_KEY,
  type: "error",
});

const handleNotifySuccess = makeResponseHandler({
  copyKey: BACK_IN_STOCK_NOTIFICATION_COPY_KEY,
  type: "success",
});

const handleTurnOffSuccess = makeResponseHandler({
  copyKey: BACK_IN_STOCK_DISABLE_NOTIFICATION_COPY_KEY,
  type: "success",
});

export async function getBackInStockVariantIds() {
  const isAuthenticated = await getCustomerIsAuthenticated();

  if (!isAuthenticated) {
    return null;
  }

  const apiData = await getApiData();

  if (!apiData) {
    return null;
  }

  return makeRequest({
    ...apiData,
    mapData: (data) => data.variantIds,
  });
}

function getBackInStockEndpoint(storeId) {
  return `/api/marketing/backinstock/v1/customers/me/stores/${storeId}/variants`;
}

export async function addVariantToNotifications({
  variantId,
  productId,
  suppressLogin,
  suppressNotification,
} = {}) {
  const isAuthenticated = await getCustomerIsAuthenticated();

  if (!isAuthenticated) {
    if (!suppressLogin) {
      initiateLogin({
        variantId,
        productId,
        copyKey: BACK_IN_STOCK_NOTIFY_MODAL_COPY_KEY,
      });
    }
    return null;
  }

  const apiData = await getApiData();

  if (!apiData || !variantId) {
    return null;
  }

  return makeRequest({
    ...apiData,
    body: {
      variantId,
    },
    headers: {
      ...apiData.headers,
      "content-type": "application/json",
    },
    method: PUT,
  })
    .then(() => {
      if (!suppressNotification) {
        handleNotifySuccess();
      }
    })
    .catch((error) => {
      if (!suppressNotification) {
        handleError(error);
      }
    });
}

export async function removeVariantFromNotifications({
  variantId,
  productId,
  suppressLogin,
  suppressNotification,
} = {}) {
  const isAuthenticated = await getCustomerIsAuthenticated();

  if (!isAuthenticated) {
    if (!suppressLogin) {
      initiateLogin({
        variantId,
        productId,
        copyKey: BACK_IN_STOCK_TURN_OFF_ALERT_MODAL_COPY_KEY,
      });
    }
    return null;
  }

  const apiData = await getApiData();

  if (!apiData || !variantId) {
    return null;
  }

  return makeRequest({
    ...apiData,
    url: `${apiData.url}/${variantId}`,
    headers: {
      ...apiData.headers,
      "content-type": "application/json",
    },
    method: DELETE,
  })
    .then(() => {
      if (!suppressNotification) {
        handleTurnOffSuccess();
      }
    })
    .catch((error) => {
      if (!suppressNotification) {
        handleError(error);
      }
    });
}
