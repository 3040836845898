import { waitForPageLoadAnalytics } from "../pageLoad";
import { fitAssistantPromise } from "./fitAssistant";
import { getCommons, getGlobalEvars } from "../common";
import getWindow from "../../utils/window";
import { setComponentPerformance } from "./componentPerformance";
import { setEvents, setLinkTrackEvents } from "../events";
import { backInStockPromise } from "./backInStock";

export const postPageLoad = async () => {
  await waitForPageLoadAnalytics;
  await fitAssistantPromise;
  await backInStockPromise;

  setComponentPerformance();
  setEvents({ events: ["event344"], trackingName: "postPageLoad" });

  const s = getWindow().s;
  const commons = getCommons();

  const overrides = {
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      "prop36",
      "eVar50",
      "eVar163",
      "eVar164",
      "eVar165",
      "eVar166",
      "eVar167",
      "eVar168",
      "eVar169",
      "eVar170",
    ]),
    linkTrackEvents: setLinkTrackEvents({ trackingName: "postPageLoad" }),
  };

  s.tl(true, "o", "post page load", overrides);
};
