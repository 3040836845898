import getWindow from "./window";
import getRuntimeConstants from "../runtimeConstants";

const SERVER_TIMING_HEADERS = [
  "edge",
  "origin",
  "ew-onclientrequest",
  "ew-onclientresponse",
  "ew-responseprovider",
];

function setPageViewAttribute(name, value) {
  getWindow().newrelic.setCustomAttribute(name, value);
}

function addTimings() {
  const navPerformance = getWindow().performance.getEntriesByType("navigation");

  if (navPerformance?.[0]?.serverTiming) {
    SERVER_TIMING_HEADERS.forEach((header) => {
      const timingForHeader = navPerformance[0].serverTiming.find(
        ({ name }) => name === header
      );

      if (timingForHeader) {
        setPageViewAttribute(`timing-${header}`, timingForHeader.duration);
      }
    });
  }
}

function addEdgeVersion() {
  if (getWindow().asos.pdp.config.edgeWorkerVersion) {
    setPageViewAttribute(
      "edgeWorkerVersion",
      getWindow().asos.pdp.config.edgeWorkerVersion
    );
  }
}

function addPageAttributes() {
  const {
    webContext: {
      storeId,
      platform,
      keyStoreDataversion,
      browseCountry,
      browseCurrency,
      browseLanguage,
      browseSizeSchema,
      templateVersion,
      buildVersion,
    },
    appVersion,
  } = getRuntimeConstants();

  setPageViewAttribute("version", appVersion);
  setPageViewAttribute("storeId", storeId);
  setPageViewAttribute("platform", platform);
  setPageViewAttribute("keyStoreDataversion", keyStoreDataversion);
  setPageViewAttribute("browseCountry", browseCountry);
  setPageViewAttribute("browseCurrency", browseCurrency);
  setPageViewAttribute("browseLanguage", browseLanguage);
  setPageViewAttribute("browseSizeSchema", browseSizeSchema);
  setPageViewAttribute("siteChromeTemplateVersion", templateVersion);
  setPageViewAttribute("siteChromeTemplateBuild", buildVersion);
  setPageViewAttribute("userAgent", getWindow().navigator.userAgent);
}

export function addPageAttributesToReporting() {
  if (getWindow().newrelic) {
    addPageAttributes();
    addTimings();
    addEdgeVersion();
  }
}
