import { createContext, useEffect, useContext, useState } from "react";
import { PropTypes } from "prop-types";
import { onReady } from "../../utils/features";

const FeaturesReadyContext = createContext({});

export const FeaturesReadyProvider = ({ children }) => {
  const [areFeaturesReady, setAreFeaturesReady] = useState(false);

  useEffect(() => {
    onReady().then(() => setAreFeaturesReady(true));
  }, []);

  return (
    <FeaturesReadyContext.Provider value={{ areFeaturesReady }}>
      {children}
    </FeaturesReadyContext.Provider>
  );
};

FeaturesReadyProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useFeaturesReady = () => useContext(FeaturesReadyContext);
