const config = {};
let loaded = false;

const getConfig = () => {
  if (!loaded) {
    Object.assign(config, window.asos.pdp.config);
    loaded = true;
  }

  return config;
};

export default getConfig;
