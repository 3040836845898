import { priceMapper } from "./price";

function getVariantSize({ displaySizeText, brandSize }) {
  if (displaySizeText) {
    return displaySizeText;
  }

  return brandSize;
}

export const variantMapper = (variant, shouldMapPrice) => {
  const {
    isInStock,
    hasIngredients,
    sizeId,
    colour,
    colourWayId,
    isPrimary,
    sizeOrder,
    seller,
    source,
    ean,
    sku,
  } = variant;

  return {
    variantId: variant.id,
    size: getVariantSize(variant),
    price: shouldMapPrice ? priceMapper(variant) : undefined,
    isLowInStock: shouldMapPrice ? variant.isLowInStock : undefined,
    isInStock,
    hasIngredients,
    sizeId,
    colour,
    colourWayId,
    isPrimary,
    sizeOrder,
    seller,
    source,
    ean,
    sku,
  };
};
