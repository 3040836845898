import { createContext, useContext } from "react";
import { PropTypes } from "prop-types";
import useLayoutState from "../../hooks/useLayoutState";

const ViewportContext = createContext(null);

export const ViewportProvider = ({ children }) => {
  const viewportCategory = useLayoutState();

  return (
    <ViewportContext.Provider value={{ viewportCategory }}>
      {children}
    </ViewportContext.Provider>
  );
};

ViewportProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useViewport = () => useContext(ViewportContext);
