import { LOWEST_PRICE_IN_LAST_30_DAYS_COUNTRIES } from "../../constants";

export function isLowestPriceInLast30DaysEnabled(browseCountry) {
  return LOWEST_PRICE_IN_LAST_30_DAYS_COUNTRIES.includes(browseCountry);
}

function isUndefined(
  lowestPriceInLast30DaysValue,
  lowestPriceInLast30DaysText,
  lowestPriceInLast30DaysPercentage
) {
  return (
    lowestPriceInLast30DaysPercentage === undefined ||
    lowestPriceInLast30DaysValue === undefined ||
    lowestPriceInLast30DaysText === undefined
  );
}

export function buildLowestPriceInLast30DaysPrice({
  translations,
  lowestPriceInLast30DaysValue,
  lowestPriceInLast30DaysText,
  lowestPriceInLast30DaysPercentage,
}) {
  if (
    isUndefined(
      lowestPriceInLast30DaysValue,
      lowestPriceInLast30DaysText,
      lowestPriceInLast30DaysPercentage
    ) ||
    (lowestPriceInLast30DaysPercentage === null &&
      lowestPriceInLast30DaysValue === null &&
      lowestPriceInLast30DaysText === null)
  ) {
    return "";
  }

  return `${translations(
    "lowest_price_last_thirty_days"
  )} ${lowestPriceInLast30DaysText}`;
}

export function buildLowestPriceInLast30DaysPercentageDiscountLabel(
  lowestPriceInLast30DaysPercentage
) {
  const isNumber =
    lowestPriceInLast30DaysPercentage &&
    typeof lowestPriceInLast30DaysPercentage === "number";

  if (lowestPriceInLast30DaysPercentage === 0) {
    return `${lowestPriceInLast30DaysPercentage}%`;
  }
  return isNumber
    ? `${
        lowestPriceInLast30DaysPercentage < 0
          ? `+${-lowestPriceInLast30DaysPercentage}`
          : `${-lowestPriceInLast30DaysPercentage}`
      }%`
    : "";
}

export function buildLowestPriceInLast30DaysAriaLabel({
  translations,
  lowestPriceInLast30DaysText,
  lowestPriceInLast30DaysValue,
  lowestPriceInLast30DaysPercentage,
}) {
  const text = buildLowestPriceInLast30DaysPrice({
    translations,
    lowestPriceInLast30DaysText,
    lowestPriceInLast30DaysValue,
    lowestPriceInLast30DaysPercentage,
  });

  if (!text) {
    return "";
  }

  const percentage = buildLowestPriceInLast30DaysPercentageDiscountLabel(
    lowestPriceInLast30DaysPercentage
  );

  return `${text} (${percentage})`;
}
