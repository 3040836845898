import getWindow from "../../utils/window";
import getPreviousValue from "../../utils/getPreviousValue";

export function setVisitorIdentifierAnalytics() {
  const s = getWindow().s;
  const eVar198FromPLP = getPreviousValue("gpv_e198");
  if (!eVar198FromPLP) {
    return;
  }

  s.eVar198 = eVar198FromPLP;
}
