import { setEvents } from "../events";
import getWindow from "../../utils/window";
import { addMerchEVarToProducts } from "../common";
import getPreviousValue from "../../utils/getPreviousValue";

export function setCategoryPageCarouselAnalytics() {
  const s = getWindow().s;
  const eVar234FromPLP = getPreviousValue("gpv_eVar234");
  if (!eVar234FromPLP) {
    return;
  }

  s.gpv_eVar234 = eVar234FromPLP;
  s.products = addMerchEVarToProducts(s.products, `eVar234=${eVar234FromPLP}`);

  setEvents({ events: ["event316"], trackingName: "pageLoad" });
}
