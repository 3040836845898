export function priceMapper({
  price: {
    current,
    previous,
    rrp,
    xrp,
    isMarkedDown,
    isOutletPrice,
    currency,
    startDateTime,
    wasPriceStartDate,
    previousEndDate,
    lowestPriceInLast30DaysEndDate,
    lowestPriceInLast30DaysPercentage,
    lowestPriceInLast30DaysText,
    lowestPriceInLast30DaysValue,
    discountPercentage,
  },
}) {
  return {
    current: {
      text: current.text,
      value: current.value,
    },
    previous: {
      text: previous.text,
      value: previous.value,
    },
    rrp: {
      text: rrp.text,
      value: rrp.value,
    },
    xrp: {
      text: xrp.text,
      value: xrp.value,
    },
    isMarkedDown,
    isOutletPrice,
    currency,
    startDateTime,
    wasPriceStartDate,
    lowestPriceInLast30DaysEndDate,
    lowestPriceInLast30DaysPercentage,
    lowestPriceInLast30DaysText,
    lowestPriceInLast30DaysValue,
    previousEndDate,
    discountPercentage,
  };
}
