import {
  getProductSourceSellerInfo,
  getVariantSourceSellerInfo,
} from "./sourceSellerHelper";
import { productHasMultipleSizes } from "../client/utils/product";
import { VARIANT_TYPE } from "../client/constants";

export const getInStockVariants = (variants) => {
  return variants.filter((variant) => variant.isInStock);
};

export const getColours = (variants = []) => [
  ...new Set(variants.map((variant) => variant.colour)),
];

export const allVariantsHaveSeller = (variants) =>
  variants.every((variant) => getVariantSourceSellerInfo(variant).sellerId);

export const allVariantsPartnerFulfils = (variants) => {
  return getProductSourceSellerInfo({ variants }).isPartnerFulfils;
};

export const allVariantsPrimarySource = (variants) =>
  getProductSourceSellerInfo({ variants }).isPrimary;

export const allVariantsSecondarySource = (variants) =>
  getProductSourceSellerInfo({ variants }).isSecondary;

export const allVariantsAsosFulfils = (variants) => {
  return getProductSourceSellerInfo({ variants }).isAsosFulfils;
};

export const isVariantPartnerFulfils = (variants, variantId) => {
  const variant = variants.find((variant) => variant.variantId === variantId);
  if (variant) {
    return getVariantSourceSellerInfo(variant).isPartnerFulfils;
  }
  return false;
};

export const isVariantAsosFulfils = (variants, variantId) => {
  const variant = variants.find((variant) => variant.variantId === variantId);
  if (variant) {
    return getVariantSourceSellerInfo(variant).isAsosFulfils;
  }
  return false;
};

export const doesVariantHaveSeller = (variants, variantId) => {
  const variant = variants.find((variant) => variant.variantId === variantId);
  return !!(variant && getVariantSourceSellerInfo(variant).sellerId);
};

export const getPrimaryVariant = (variants) =>
  variants.find((variant) => variant.isPrimary === true);

export const getVariantsColourWayId = (variants, variantId) =>
  variants.find((variant) => variant.variantId === variantId)?.colourWayId ||
  getPrimaryVariant(variants)?.colourWayId;

export const getVariantType = ({ product }) =>
  productHasMultipleSizes(product) ? VARIANT_TYPE.size : VARIANT_TYPE.colour;
