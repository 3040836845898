import getWindow from "../../utils/window";
import getRuntimeConstants from "../../runtimeConstants";
import { setEvents } from "../events";
import { getTotalUniqueSizes } from "../../utils/getTotalUniqueSizes";
import { getTotalInStock } from "../../utils/getTotalInStock";

const getVariantStockStatus = ({ variants }) => {
  if (!variants) {
    return "";
  }

  const stockStatuses = variants.map(
    ({
      variantId,
      isInStock,
      price: {
        current: { value: variantPrice },
      },
    }) =>
      `variantstockstatus:${variantId}_${isInStock ? "1" : "0"}_${variantPrice}`
  );

  return stockStatuses.join(",");
};

const areAllMixAndMatchProductsInStock = ({ product }) =>
  product.products.some(({ isInStock }) => isInStock);

const getSingleProductStockStatusEvent = ({ product }) => {
  if (product.isDiscontinued || getTotalInStock(product) === 0) {
    return "event45";
  }

  if (getTotalInStock(product) === product.variants.length) {
    return "event43";
  }

  return "event44";
};

const getMixAndMatchStockStatusEvent = ({ product }) =>
  areAllMixAndMatchProductsInStock({ product }) ? "event43" : "event45";

export const setStockStatusPageLoadValues = ({ product } = {}) => {
  const s = getWindow().s;
  const events = [];

  const { isMixAndMatch } = getRuntimeConstants();

  if (isMixAndMatch) {
    events.push(getMixAndMatchStockStatusEvent({ product }));
  } else {
    events.push(getSingleProductStockStatusEvent({ product }));
  }

  if (!isMixAndMatch) {
    const uniqueSizes = getTotalUniqueSizes(product);
    const totalNumberOfVariants = product.variants.length;
    const numberOfInStockVariants = getTotalInStock(product);
    const productAvailability = numberOfInStockVariants / totalNumberOfVariants;
    const percentOfVariantsInStock =
      totalNumberOfVariants === 0
        ? "0"
        : `${(productAvailability * 100).toFixed(2)}%`;

    s.eVar26 = uniqueSizes;
    events.push(`event271=${uniqueSizes}`);
    events.push(`event277=${numberOfInStockVariants}`);

    s.list1 = getVariantStockStatus(product);

    s.eVar31 =
      s.eVar36 = `${totalNumberOfVariants}|${numberOfInStockVariants}|${percentOfVariantsInStock}|null|null|null|null|null|null`;

    if (totalNumberOfVariants > 0) {
      events.push(`event294=${productAvailability.toFixed(3)}`);
    }
  }

  setEvents({ events, trackingName: "pageLoad" });
};
