import { createContext, useState, useContext } from "react";
import { PropTypes } from "prop-types";

export const RuntimeConstantsContext = createContext({});

export const RuntimeConstantsProvider = ({ runtimeConstants, children }) => {
  const [{ translations, ...runtimeConstantsState }] =
    useState(runtimeConstants);

  const getTranslation = (translationKey, dynamicValue) => {
    return dynamicValue
      ? translations.t(translationKey, dynamicValue)
      : translations.t(translationKey);
  };

  return (
    <RuntimeConstantsContext.Provider
      value={{ ...runtimeConstantsState, getTranslation }}
    >
      {children}
    </RuntimeConstantsContext.Provider>
  );
};

RuntimeConstantsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  runtimeConstants: PropTypes.object.isRequired,
};

export const useRuntimeConstants = () => useContext(RuntimeConstantsContext);
