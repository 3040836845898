import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import { PropTypes } from "prop-types";
import { useLooksProducts } from "./LooksProductsContext";
import { getPreselectedProduct } from "../../utils/product";
import { usePreviousBackInStock } from "../backInStock/BackInStockContext";

export const SelectedLooksProductContext = createContext({});

export const SelectedLooksProductProvider = ({ children }) => {
  const { looksProducts } = useLooksProducts();
  const [selectedLooksProduct, setSelectedLooksProduct] = useState(() => {
    if (looksProducts?.length) {
      return looksProducts[1];
    }
  });
  const { previousBackInStockProductId } = usePreviousBackInStock();

  useEffect(() => {
    if (!selectedLooksProduct && looksProducts?.length) {
      setSelectedLooksProduct(looksProducts[1]);
    }
  }, [selectedLooksProduct, looksProducts]);

  const handleLooksCarouselClick = useCallback(
    (productId) => {
      setSelectedLooksProduct(looksProducts.find(({ id }) => productId === id));
    },
    [looksProducts]
  );

  useEffect(() => {
    const preSelectedLooksProduct = getPreselectedProduct(
      looksProducts,
      previousBackInStockProductId
    );
    if (preSelectedLooksProduct) {
      setSelectedLooksProduct(preSelectedLooksProduct);
    }
  }, [looksProducts, previousBackInStockProductId]);

  return (
    <SelectedLooksProductContext.Provider
      value={{
        selectedLooksProduct,
        setSelectedLooksProduct: handleLooksCarouselClick,
      }}
    >
      {children}
    </SelectedLooksProductContext.Provider>
  );
};

export const useSelectedLooksProduct = () =>
  useContext(SelectedLooksProductContext);

SelectedLooksProductProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
