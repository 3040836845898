import { useEffect, useState } from "react";
import { useRuntimeConstants } from "../runtimeConstants/RuntimeConstantsContext";
import { useStockPrice } from "../stockPrice/StockPriceContext";

function getVariants({ products, isMixAndMatch }) {
  if (isMixAndMatch) {
    return products.products?.map(({ variants }) => variants).flat();
  }

  return products.variants;
}

export const useIsInStock = () => {
  const { product, isMixAndMatch } = useRuntimeConstants();
  const { products } = useStockPrice();
  const [isInStock, setIsInStock] = useState(null);

  useEffect(() => {
    if (!products) {
      return;
    }

    const variants = getVariants({ products, isMixAndMatch });

    if (product.isDiscontinued || variants.length === 0) {
      setIsInStock(false);
      return;
    }

    setIsInStock(variants.some((variant) => variant.isInStock));
  }, [product, products, isMixAndMatch]);

  return isInStock;
};
