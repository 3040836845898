import getWindow from "../../utils/window";
import { addMerchEVarToProducts } from "../common";
import getPreviousValue from "../../utils/getPreviousValue";

export function setFeatureProductAnalytics() {
  const s = getWindow().s;
  const eVar193FromPLP = getPreviousValue("gpv_eVar193");
  if (!eVar193FromPLP) {
    return;
  }

  s.gpv_eVar193 = eVar193FromPLP;
  s.products = addMerchEVarToProducts(s.products, `eVar193=${eVar193FromPLP}`);
}
