const getRestockingSoonDetails = ({
  stockPriceProduct = {
    isRestockingSoon: false,
    restockingLeadTime: null,
  },
  stockStatus = { isInStock: false },
}) => ({
  shouldShowRestockingSoon:
    stockPriceProduct.isRestockingSoon && !stockStatus.isInStock,
  restockingLeadTimeWeeks: Math.ceil(
    (stockPriceProduct.restockingLeadTime || 1) / 7
  ),
});

export default getRestockingSoonDetails;
