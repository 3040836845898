import getWindow from "../../utils/window";
import getRuntimeConstants from "../../runtimeConstants";
import { setEvents } from "../events";
import getPreviousValue from "../../utils/getPreviousValue";

const getViewportDimensions = () => {
  const height = getWindow().innerHeight;
  const width = getWindow().innerWidth;

  return width && height ? `${width}:${height}` : "";
};

const getChannel = ({ urlParams, referrerData }) => {
  if (referrerData?.cid) {
    return referrerData.cid;
  }

  if (referrerData?.searchQuery) {
    return "search";
  }

  if (urlParams.ctaref) {
    return getCtarefChannel(urlParams);
  }

  return "product page";
};

const getCtarefChannel = ({ ctaref }) => {
  if (ctaref.includes("bag")) {
    return "basket page";
  }
  if (ctaref.includes("saved items")) {
    return "saved items";
  }
  if (ctaref.includes("wishlists")) {
    return "wishlists";
  }
};

export const setGeneralPageLoadAnalytics = () => {
  const s = getWindow()?.s;
  const events = s.events ? s.events.split(",") : [];

  const {
    webContext,
    appVersion,
    urlParams,
    analyticsBreadcrumbs,
    referrerData,
  } = getRuntimeConstants();

  s.channel = getChannel({ urlParams, referrerData });
  s.prop8 = "product page";
  s.server = `${webContext.platform} ${webContext.storeId.toLowerCase()}`;
  s.campaign = s.getCampaignID();
  s.eVar1 = s.getVisitNum();
  s.eVar17 = s.getNewRepeat(365);
  s.eVar70 = s.getBagItems();
  s.prop5 = s.getCustomTimeDate();
  s.eVar45 = appVersion;
  s.eVar112 = webContext.browseCountry.toLowerCase();
  s.eVar113 = webContext.browseCurrency.toLowerCase();
  s.prop11 = encodeURI(getWindow().location.href.replace(/%20/gi, "+"));
  s.eVar19 = getViewportDimensions();
  s.eVar25 = referrerData?.searchQuery;
  s.prop6 = s.getQueryParamValue("ctaref", true)?.toLowerCase();

  const eVar231 = getPreviousValue("gpv_e231");
  if (eVar231) {
    s.eVar231 = eVar231;
  }

  s.setContentHierarchy(
    "product page",
    analyticsBreadcrumbs,
    referrerData?.cid
  );

  if ("performance" in getWindow()) {
    const navApi = getWindow().performance.timing;
    const responseToNavigation = navApi.responseStart - navApi.navigationStart;
    const domContentToNavigation =
      navApi.domContentLoadedEventEnd - navApi.navigationStart;

    s.eVar29 = responseToNavigation;
    s.eVar30 = domContentToNavigation;
    events.push(`event128=${domContentToNavigation}`);
  }

  events.push("prodView");
  events.push("event1");
  events.push("event35");

  s.eVar20 = s.eVar6;

  setEvents({ events, trackingName: "pageLoad" });
};
