import { getCustomerIsAuthenticated } from "../utils/identity";

export const NEW_USER = "new user";

function getFitAssistantState({
  recommendedSize,
  outOfScale,
  isPartialRecommendation,
  isProductUnsupported,
}) {
  if (recommendedSize === NEW_USER) {
    if (!isProductUnsupported) {
      return "first time interaction";
    }
    return "fa not present";
  }

  if (outOfScale) {
    return "out of scale";
  }

  return isPartialRecommendation
    ? "partial recommendation"
    : "full recommendation";
}

async function getSignedInStatus() {
  const customerisAuthenticated = await getCustomerIsAuthenticated();

  return customerisAuthenticated ? "signed in" : "not signed in";
}

function getUserProfile({ usedPastPurchases, isProductUnsupported }) {
  if (isProductUnsupported) {
    return null;
  }

  return usedPastPurchases
    ? "previous purchase data"
    : "no previous purchase data";
}

function getCategory({ category, isProductUnsupported }) {
  return category && !isProductUnsupported ? category : null;
}

function getStockStatus({ outOfStock, isProductUnsupported }) {
  if (isProductUnsupported) {
    return "na";
  }
  return outOfStock ? "recommendation not in stock" : "recommendation in stock";
}

export const getFitAssistantStatus = async ({
  recommendedSize,
  outOfScale,
  isPartialRecommendation,
  isProductUnsupported,
  usedPastPurchases,
  category,
  customerPreferences,
  outOfStock,
  invalidFitProduct,
  noFitStatus,
}) => {
  const signedInStatus = await getSignedInStatus();

  if (noFitStatus) {
    return `${signedInStatus}-fa status not available-null-null-na`;
  }

  if (invalidFitProduct) {
    return `${signedInStatus}-fa not present-null-null-na`;
  }

  if (!customerPreferences) {
    return `${signedInStatus}-cookies blocked-null-null-na`;
  }

  const fitAssistantState = getFitAssistantState({
    recommendedSize,
    outOfScale,
    isPartialRecommendation,
    isProductUnsupported,
  });
  const userProfile = getUserProfile({
    usedPastPurchases,
    isProductUnsupported,
  });
  const bodyCategory = getCategory({ category, isProductUnsupported });
  const stockStatus = getStockStatus({ outOfStock, isProductUnsupported });

  return `${signedInStatus}-${fitAssistantState}-${userProfile}-${bodyCategory}-${stockStatus}`;
};

export const getFitAssistantSize = ({ storeCode, recommendedSize }) =>
  `${storeCode}|${
    recommendedSize === NEW_USER ? "no recommended size set" : recommendedSize
  }`.toLowerCase();
