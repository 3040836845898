function getRatingAsPercent(rating) {
  return (rating / 5).toFixed(2);
}

function formatPercent(percent) {
  return (percent / 100).toFixed(2);
}

export function hasRatings(ratings) {
  return !!ratings && !!Object.keys(ratings).length;
}

export function getAverageOverallRating(ratings) {
  let eVar185;
  let merchEVar189;
  let event257;

  if (hasRatings(ratings)) {
    if (ratings.averageOverallRating) {
      const averageOverallRating = getRatingAsPercent(
        ratings.averageOverallRating
      );
      eVar185 = averageOverallRating;
      merchEVar189 = `eVar189=${averageOverallRating}`;
      event257 = `event257=${averageOverallRating}`;
    }
  } else {
    merchEVar189 = "eVar189=value not in response";
  }

  return {
    eVar185,
    merchEVar189,
    event257,
  };
}

export function getTotalReviewCount(ratings) {
  let eVar186;
  let merchEVar190;
  let event258;

  if (hasRatings(ratings)) {
    if (ratings.totalReviewCount) {
      const totalReviewCount = ratings.totalReviewCount.toString();

      eVar186 = totalReviewCount;
      merchEVar190 = `eVar190=${totalReviewCount}`;
      event258 = `event258=${totalReviewCount}`;
    }
  } else {
    merchEVar190 = "eVar190=value not in response";
  }

  return {
    eVar186,
    merchEVar190,
    event258,
  };
}

export function getPercentageRecommended(ratings) {
  let eVar187;
  let merchEVar191;
  let event259;

  if (hasRatings(ratings)) {
    if (ratings.percentageRecommended) {
      eVar187 = ratings.percentageRecommended;
      event259 = `event259=${ratings.percentageRecommended}`;
      merchEVar191 = `eVar191=${ratings.percentageRecommended}`;
    }
  } else {
    merchEVar191 = "eVar191=value not in response";
  }

  return {
    eVar187,
    merchEVar191,
    event259,
  };
}

export function getMostRecentRating(ratings) {
  let eVar201;
  let merchEVar202;
  let event274;

  if (hasRatings(ratings)) {
    if (ratings.mostRecent?.rating) {
      const mostRecentRating = getRatingAsPercent(ratings.mostRecent.rating);

      eVar201 = mostRecentRating;
      merchEVar202 = `eVar202=${mostRecentRating}`;
      event274 = `event274=${mostRecentRating}`;
    }
  } else {
    merchEVar202 = "eVar202=value not in response";
  }

  return {
    eVar201,
    merchEVar202,
    event274,
  };
}

export function getFirstSubRatingValue(ratings) {
  let eVar188 = "value not in response|value not in response";
  let merchEVar192;
  let event260;

  if (hasRatings(ratings)) {
    if (ratings.subRatings?.length) {
      const firstSubRating = ratings.subRatings[0];
      const firstSubRatingPercentage = formatPercent(firstSubRating.percentage);

      eVar188 = `${firstSubRating.id}|${firstSubRatingPercentage}`;
      merchEVar192 = `eVar192=${firstSubRating.id} ${firstSubRatingPercentage}`;
      event260 = `event260=${firstSubRatingPercentage}`;
    }
  } else {
    merchEVar192 = "eVar192=value not in response";
  }

  return {
    eVar188,
    merchEVar192,
    event260,
  };
}
