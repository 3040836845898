import { useEffect } from "react";
import { useIsInStockContext } from "../context/isInStock/IsInStockContext";
import { initialiseFeatures } from "../utils/features";
import { useRuntimeConstants } from "../context/runtimeConstants/RuntimeConstantsContext";

export default function useFeatures() {
  const { isStockStateReady, isInStock } = useIsInStockContext();
  const { isMixAndMatch } = useRuntimeConstants();

  useEffect(() => {
    if (isStockStateReady) {
      initialiseFeatures(isMixAndMatch, isInStock);
    }
  }, [isStockStateReady, isMixAndMatch, isInStock]);
}
