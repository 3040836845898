import getWindow from "../../utils/window";
import { setEvents } from "../events";
import { addMerchEVarToProducts } from "../common";

let isShowing = false;

export const setSaveCount = (numSaves) => {
  if (!getWindow()) {
    return;
  }
  const s = getWindow().s;

  if (!isShowing) {
    s.products = addMerchEVarToProducts(s.products, `eVar228=${numSaves}`);
    setEvents({ events: ["event313"], trackingName: "pageLoad" });
    isShowing = true;
    getWindow().asos.pdp.config.product.saveCount.isShowing = true;
  }
};

export const isShowingSaveCount = () => {
  return isShowing;
};
