const productFlags = new Set();

export function getProductFlags() {
  return Array.from(productFlags).join("~");
}

export function setProductFlag(value) {
  productFlags.add(value);
}

export function buildProductFlag({ key, shown }) {
  return `${key}:${shown ? "y" : "n"}`;
}
