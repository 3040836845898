import { setProductFlag, buildProductFlag } from "./productFlags";
import { setEvents } from "../events";

export default function setLooksTracking(looksShown) {
  if (looksShown) {
    setEvents({ events: ["event341"], trackingName: "pageLoad" });
  }

  setProductFlag(buildProductFlag({ key: "bca", shown: looksShown }));
}
