import loadable from "@loadable/component";

const SingleProductPage = loadable(
  () =>
    import(/* webpackChunkName: "page-singleProduct" */ "./SingleProductPage"),
  {
    ssr: true,
  }
);
const MixAndMatchPage = loadable(
  () => import(/* webpackChunkName: "page-mixAndMatch" */ "./MixAndMatchPage"),
  {
    ssr: true,
  }
);

export { SingleProductPage, MixAndMatchPage };
