import { createContext, useContext, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  BACK_IN_STOCK_SELECTED_VARIANT,
  BACK_IN_STOCK_SELECTED_PRODUCT,
} from "../../constants";
import {
  removeSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/storage";

const BackInStockContext = createContext(null);

export const BackInStockProvider = ({ children }) => {
  const [previousBackInStockVariantId, setPreviousBackInStockVariantId] =
    useState(null);
  const [previousBackInStockProductId, setPreviousBackInStockProductId] =
    useState(null);

  useEffect(() => {
    const originalVariantId = getSessionStorageItem(
      BACK_IN_STOCK_SELECTED_VARIANT
    );
    const originalProductId = getSessionStorageItem(
      BACK_IN_STOCK_SELECTED_PRODUCT
    );
    setPreviousBackInStockVariantId(originalVariantId);
    setPreviousBackInStockProductId(originalProductId);
    setTimeout(() => {
      removeSessionStorageItem(BACK_IN_STOCK_SELECTED_VARIANT);
      removeSessionStorageItem(BACK_IN_STOCK_SELECTED_PRODUCT);
    }, 1);
  }, []);

  return (
    <BackInStockContext.Provider
      value={{ previousBackInStockVariantId, previousBackInStockProductId }}
    >
      {children}
    </BackInStockContext.Provider>
  );
};

BackInStockProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const usePreviousBackInStock = () => useContext(BackInStockContext);
