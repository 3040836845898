const DEFAULT_INFO = {
  isPrimary: false,
  isSecondary: false,
  isPartnerFulfils: false,
  isAsosFulfils: false,
  sellerLabel: null,
  sourceLabel: null,
  sourceId: null,
  sellerId: null,
};

Object.freeze(DEFAULT_INFO);

const isPartnerFulfils = ({ source = null, seller = null }) => {
  if (source !== null && seller !== null && source.id === seller.id) {
    return true;
  }
};

const isSecondaryAsosFulfils = ({ source = null, seller = null }) => {
  if (source !== null && seller !== null && source.id !== seller.id) {
    return true;
  }
};

const isPrimaryAsosFulfils = ({ source = null, seller = null }) => {
  if (source === null && seller !== null) {
    return true;
  }
};

const isSecondary = ({ source = null, seller = null }) => {
  if (source !== null && seller?.id !== source.id) {
    return true;
  }
};

const isPrimary = ({ source = null }) => {
  if (source === null) {
    return true;
  }
};

const isAsosSold = ({ seller }) => seller === null;

const areAllAsosFulfils = ({ variants }) => {
  return variants.every(
    (variant) =>
      isPrimaryAsosFulfils(variant) || isSecondaryAsosFulfils(variant)
  );
};

const areAllPartnerFulfils = ({ variants }) => {
  return variants.every((variant) => isPartnerFulfils(variant));
};

const areAllSameSecondary = ({ variants }) => {
  if (isSecondary(variants[0])) {
    const firstInfo = variants[0].source;
    return variants.every((variant) => {
      return isSecondary(variant) && firstInfo.id === variant.source?.id;
    });
  }
};

const consistentSourceSeller = ({ variants }) => {
  const { source, seller } = variants[0];
  return variants.every(
    (variant) =>
      variant.source?.id === source?.id && variant.seller?.id === seller?.id
  );
};

const areAllSecondary = ({ variants }) => {
  return variants.every((variant) => isSecondary(variant));
};

const areAllPrimary = ({ variants }) => {
  return variants.every((variant) => isPrimary(variant));
};

export const getVariantSourceSellerInfo = ({ seller = null, source = null }) =>
  getProductSourceSellerInfo({ variants: [{ seller, source }] });

/* eslint-disable complexity */
export const getProductSourceSellerInfo = ({ variants }) => {
  if (!variants?.length) {
    return { ...DEFAULT_INFO, isAllAsosSold: true };
  }
  const { source, seller } = variants[0];
  const isPrimary = areAllPrimary({ variants });
  const isSecondary = areAllSecondary({ variants });
  const isConsistent = consistentSourceSeller({ variants });
  const isAllAsosSold = isConsistent && isAsosSold({ seller });

  if (areAllAsosFulfils({ variants })) {
    if (isPrimary) {
      return {
        ...DEFAULT_INFO,
        isConsistent,
        isPrimary,
        isSecondary,
        isAsosFulfils: true,
        sourceId: "primary",
        sourceLabel: "ASOS",
        sellerLabel: seller.description,
        sellerId: seller.id,
        isAllAsosSold: false,
      };
    } else if (isSecondary) {
      return {
        ...DEFAULT_INFO,
        isConsistent,
        isPrimary,
        isSecondary,
        isAsosFulfils: true,
        sourceId: source.id,
        sourceLabel: source.description,
        sellerLabel: seller.description,
        sellerId: seller.id,
        isAllAsosSold: false,
      };
    } else {
      return {
        ...DEFAULT_INFO,
        isConsistent,
        isPrimary,
        isSecondary,
        isAsosFulfils: true,
        sourceId: "ASOS",
        sourceLabel: "ASOS",
        sellerLabel: seller.description,
        sellerId: seller.id,
        isAllAsosSold: false,
      };
    }
  }

  if (areAllPartnerFulfils({ variants })) {
    return {
      ...DEFAULT_INFO,
      isConsistent,
      isPrimary,
      isSecondary,
      isPartnerFulfils: true,
      sourceLabel: source.description,
      sourceId: source.id,
      sellerLabel: seller.description,
      sellerId: seller.id,
      isAllAsosSold: false,
    };
  }

  if (areAllSameSecondary({ variants })) {
    return {
      ...DEFAULT_INFO,
      isConsistent,
      sourceLabel: source.description,
      sourceId: source.id,
      isSecondary: true,
      isAllAsosSold,
    };
  }

  return { ...DEFAULT_INFO, isConsistent, isPrimary, isAllAsosSold };
};
/* eslint-enable complexity */
