import { buildCurrentAriaLabel } from "./currentPrice";
import { buildLowestPriceInLast30DaysAriaLabel } from "./lowestPriceInLast30Days";

function getMarkedDownPricePrefix({ translations, showWasLabel }) {
  if (showWasLabel) {
    return `${translations("was_pricing_label_pdp")} `;
  }

  return "";
}

export function buildMarkedDownPrice({ previous, translations, showWasLabel }) {
  if (!previous?.text) {
    return "";
  }

  return `${getMarkedDownPricePrefix({ translations, showWasLabel })}${
    previous.text
  }`;
}

export function buildMarkedDownAriaLabel({
  previous,
  current,
  translations,
  showFromLabel,
  showWasLabel,
  showLowestPriceInLast30Days,
  lowestPriceInLast30DaysText,
  lowestPriceInLast30DaysValue,
  lowestPriceInLast30DaysPercentage,
}) {
  if (!previous?.text || !current?.text) {
    return "";
  }

  if (showWasLabel) {
    const buildWasAriaLabel = () => {
      const currentPriceAriaLabel = buildCurrentAriaLabel({
        current,
        translations,
        showFromLabel,
        showNowLabel: true,
      });

      const lowestPriceInLast30DaysAriaLabel =
        showLowestPriceInLast30Days &&
        buildLowestPriceInLast30DaysAriaLabel({
          translations,
          lowestPriceInLast30DaysText,
          lowestPriceInLast30DaysValue,
          lowestPriceInLast30DaysPercentage,
        });

      const markedDownArialLabel = buildMarkedDownPrice({
        previous,
        translations,
        showWasLabel,
      });

      return `${currentPriceAriaLabel}. ${
        lowestPriceInLast30DaysAriaLabel
          ? `${lowestPriceInLast30DaysAriaLabel}. `
          : ""
      }${markedDownArialLabel}.`;
    };

    return buildWasAriaLabel();
  }

  return `${translations("pdp_price_was")} ${previous.text}.${
    showFromLabel ? ` ${translations("pdp_misc_from")}` : ""
  } ${translations("pdp_price_sale")} ${current.text}.`;
}
