import { useEffect } from "react";

import getWindow from "../utils/window";

const useMatchMedia = (mediaQueryString, onChangeHandler) => {
  useEffect(() => {
    if (!getWindow()) {
      return;
    }
    const mediaQueryList = getWindow().matchMedia(mediaQueryString);

    mediaQueryList.addEventListener
      ? mediaQueryList.addEventListener("change", onChangeHandler)
      : mediaQueryList.addListener(onChangeHandler);

    onChangeHandler(mediaQueryList);

    return () => {
      mediaQueryList.removeEventListener
        ? mediaQueryList.removeEventListener("change", onChangeHandler)
        : mediaQueryList.removeListener(onChangeHandler);
    };
  }, [mediaQueryString, onChangeHandler]);
};

export default useMatchMedia;
