import { PAGE_AREAS } from "../constants";
import getWindow from "../utils/window";

const { MIGHT_LIKE, BUY_THE_LOOK, RECENTLY_VIEWED, OUT_OF_STOCK, MAIN } =
  PAGE_AREAS;

let commons;

export function getCommons() {
  if (!commons && window.CommonAnalytics) {
    commons = new window.CommonAnalytics();
  }
  return commons;
}

export function getProductAreaAttribute(pageArea) {
  return {
    [BUY_THE_LOOK]: "buy the look",
    [MIGHT_LIKE]: "you might also like",
    [RECENTLY_VIEWED]: "recently viewed",
    [OUT_OF_STOCK]: "oos product page",
    [MAIN]: "product page",
  }[pageArea.toLowerCase()];
}

export function addMerchEVarToProducts(sProductsString, evar) {
  const delimiter = ",;";

  return (sProductsString || "")
    .split(delimiter)
    .map((product) => {
      if (/.*;;;;evar/i.test(product)) {
        product = product + "|" + evar;
      } else {
        product = product + ";;;;" + evar;
      }

      return product;
    })
    .join(delimiter);
}

export function getGlobalEvars() {
  return [
    "eVar1",
    "eVar6",
    "eVar10",
    "eVar15",
    "eVar17",
    "eVar18",
    "eVar19",
    "eVar20",
    "eVar29",
    "eVar30",
    "eVar45",
    "eVar47",
    "eVar60",
    "eVar61",
    "eVar69",
    "eVar112",
    "eVar113",
    "eVar198",
  ];
}

export function getAsosFulfilsPrefix({ sellerId, sourceId }) {
  return sellerId && sellerId !== "asos" && sourceId !== sellerId
    ? "afs - "
    : "";
}

export function getCtaRef(caseInsensitive = true) {
  const s = getWindow()?.s;
  return s && s.getQueryParamValue("ctaref", caseInsensitive);
}
