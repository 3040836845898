import getWindow from "../../utils/window";
import {
  getFitAssistantSize,
  getFitAssistantStatus,
} from "../fitAssistantCommon";
import { setEvents } from "../events";

let fitAssistantResolve;

export const fitAssistantPromise = new Promise(
  (resolve) => (fitAssistantResolve = resolve)
);

export const ignoreFitAssistantAnalytics = () => fitAssistantResolve();

export const setFitAssistantAnalytics = async ({
  recommendedSize,
  outOfScale,
  isPartialRecommendation,
  isProductUnsupported,
  usedPastPurchases,
  category,
  customerPreferences,
  outOfStock,
  storeCode,
  invalidFitProduct,
}) => {
  const s = getWindow().s;

  s.prop36 = await getFitAssistantStatus({
    recommendedSize,
    outOfScale,
    isPartialRecommendation,
    isProductUnsupported,
    usedPastPurchases,
    category,
    customerPreferences,
    outOfStock,
    invalidFitProduct,
  });

  if (customerPreferences && !invalidFitProduct) {
    s.eVar50 = getFitAssistantSize({ storeCode, recommendedSize });
    setEvents({ events: ["event50"], trackingName: "postPageLoad" });
  }
  fitAssistantResolve();
};
