import getWindow from "./utils/window";

const URL_PARAM_NUMBER_IDS = ["colourwayid", "variantid"];

const buildParamsObject = () => {
  const { hash, searchParams } = new URL(getWindow().location);
  const hashParams = {};
  const queryParams = {};

  const hashAsSearchString = hash
    .replace("#", "?")
    .replace(/-/g, "=")
    .toLowerCase();

  const hashAsSearchParam = new URLSearchParams(hashAsSearchString);

  for (const [key, value] of hashAsSearchParam.entries()) {
    hashParams[key.toLowerCase()] = value;
  }
  for (const [key, value] of searchParams.entries()) {
    queryParams[key.toLowerCase()] = value;
  }

  return { ...queryParams, ...hashParams };
};

const checkIfParamIsNumber = (param) => URL_PARAM_NUMBER_IDS.includes(param);

const buildUrlParamsObject = () => {
  const urlParams = buildParamsObject();

  for (const [key, value] of Object.entries(urlParams)) {
    checkIfParamIsNumber(key)
      ? (urlParams[key] = parseInt(value, 10))
      : (urlParams[key] = value);
  }

  return urlParams;
};

export default buildUrlParamsObject;
