import getWindow from "../../utils/window";
import { setEvents } from "../events";
import { allVariantsAsosFulfils } from "../../../utils/variantUtils";
import { hasPageLoadFired } from "./pageLoadTrackingFired";

const sources = new Set();

const getNumberOfUniqueNonPrimarySources = (variants) => {
  variants.forEach((variant) => {
    if (variant.source) {
      sources.add(variant.source?.id);
    } else if (variant.seller) {
      sources.add(variant.seller?.id);
    }
  }, []);

  return sources.size;
};

const appendToList = (listString, value) => {
  const list = listString ? listString.split(",") : [];

  if (!list.includes(value)) {
    list.push(value);
  }

  return list.join(",");
};

const createSourceInfo = (productId, variants, fulfillerId) =>
  `${productId}:${
    allVariantsAsosFulfils(variants) ? "afs - " : ""
  }${fulfillerId}`;

let event288 = false;

const shouldUpdateEvent288 = (hasShippedByLabel) => {
  if (hasShippedByLabel && !event288) {
    event288 = true;
    return true;
  }
  return false;
};

const setEVar210 = ({ s, productId, variants, fulfillerId }) => {
  s.eVar210 = appendToList(
    s.eVar210,
    createSourceInfo(productId, variants, fulfillerId.toLowerCase())
  );
};

const setEVar212 = ({ s, variants }) => {
  s.eVar212 = getNumberOfUniqueNonPrimarySources(variants).toString();
};

const setEVar214 = ({ s, sellerId, productId, variants }) => {
  if (!sellerId) {
    s.eVar214 = appendToList(s.eVar214, `${productId}:asos`);
  } else {
    s.eVar214 = appendToList(
      s.eVar214,
      createSourceInfo(productId, variants, sellerId.toLowerCase())
    );
  }
};

export const setShippingSourcesForProduct = ({
  fulfillerId,
  sellerId,
  hasShippedByLabel,
  productId,
  variants,
}) => {
  const s = getWindow()?.s;

  if (!s || hasPageLoadFired()) {
    return;
  }

  setEVar212({ s, variants });

  if (!fulfillerId) {
    return;
  }

  setEVar210({ s, variants, productId, fulfillerId });
  setEVar214({ s, sellerId, productId, variants });

  if (shouldUpdateEvent288(hasShippedByLabel)) {
    setEvents({ events: ["event288"], trackingName: "pageLoad" });
  }
};
