import {
  createContext,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import { PropTypes } from "prop-types";

const LiveRegionContext = createContext(null);

export const LiveRegionProvider = ({ children }) => {
  const [liveRegionText, setLiveRegionText] = useState("");
  const liveRegionTimeoutIdRef = useRef(null);

  const updateLiveRegion = useCallback((text) => {
    liveRegionTimeoutIdRef.current &&
      clearTimeout(liveRegionTimeoutIdRef.current);

    setLiveRegionText(text);

    liveRegionTimeoutIdRef.current = setTimeout(() => {
      setLiveRegionText("");
    }, 1000);
  }, []);

  return (
    <LiveRegionContext.Provider value={{ liveRegionText, updateLiveRegion }}>
      {children}
    </LiveRegionContext.Provider>
  );
};

LiveRegionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useLiveRegion = () => useContext(LiveRegionContext);
