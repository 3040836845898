import { waitForPageLoadAnalytics } from "./pageLoad";
import { getCommons, getGlobalEvars } from "./common";
import { getCommonLooksTrackingValues } from "./looks/common";
import getWindow from "../utils/window";
import { clearEvents, setEvents, setLinkTrackEvents } from "./events";

export const BACK_IN_STOCK_NOTIFY_ME_CLICK_TRACKING_NAME =
  "backInStockNotifyMeClick";

export default async function fireNotifyMeClickAnalytics(
  selectedVariantId,
  pageArea,
  looksProducts
) {
  await waitForPageLoadAnalytics;

  const s = getWindow().s;
  const trackVars = [];
  let overrides = {};

  clearEvents({ trackingName: BACK_IN_STOCK_NOTIFY_ME_CLICK_TRACKING_NAME });
  setEvents({
    events: ["event214"],
    trackingName: BACK_IN_STOCK_NOTIFY_ME_CLICK_TRACKING_NAME,
  });
  s.products = `;${selectedVariantId}`;

  if (pageArea === "looks") {
    const commonLooksTracking = getCommonLooksTrackingValues({
      products: looksProducts,
    });

    overrides = {
      ...overrides,
      ...commonLooksTracking,
    };

    trackVars.push(...Object.keys(commonLooksTracking));
  }

  overrides = {
    ...overrides,
    linkTrackEvents: setLinkTrackEvents({
      trackingName: BACK_IN_STOCK_NOTIFY_ME_CLICK_TRACKING_NAME,
    }),
    linkTrackVars: getCommons().setLinkTrackVars([
      ...getGlobalEvars(),
      ...trackVars,
    ]),
  };

  s.tl(true, "o", "back in stock - notify me", overrides);
}
