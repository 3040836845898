import getProductBeaconsMapFromStorage from "./getProductBeaconsMapFromStorage";
import { PRODUCT_BEACONS_MAP_KEY } from "./constants";
import getWindow from "../window";

const sendBeacon = (productId, beaconName) => {
  const productBeaconsMap = getProductBeaconsMapFromStorage();
  const productBeacons = productBeaconsMap[productId];
  if (!productBeacons) {
    return;
  }

  const beacon = productBeacons[beaconName];

  if (!beacon) {
    return;
  }

  getWindow().navigator.sendBeacon(beacon);
  delete productBeacons[beaconName];

  if (Object.keys(productBeacons).length === 0) {
    delete productBeaconsMap[productId];
  }

  sessionStorage.setItem(
    PRODUCT_BEACONS_MAP_KEY,
    JSON.stringify(productBeaconsMap)
  );
};

export default sendBeacon;
