import getWindow from "../../utils/window";
import { setEvents } from "../events";
import {
  getAverageOverallRating,
  getMostRecentRating,
  getTotalReviewCount,
  hasRatings,
} from "../ratings";

export const setRatings = (ratings) => {
  const s = getWindow()?.s;
  const events = [];

  if (hasRatings(ratings)) {
    const { eVar185, event257 } = getAverageOverallRating(ratings);
    const { eVar186, event258 } = getTotalReviewCount(ratings);

    s.eVar185 = eVar185;
    s.eVar186 = eVar186;

    events.push(event257);
    events.push(event258);
    events.push("event275");

    if (ratings.mostRecent) {
      const { eVar201, event274 } = getMostRecentRating(ratings);

      s.eVar201 = eVar201;
      events.push(event274);
    }
  }

  setEvents({ events, trackingName: "pageLoad" });
};
