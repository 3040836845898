import getWindow from "../../utils/window";
import { setEvents } from "../events";
import { addMerchEVarToProducts } from "../common";
import getPreviousValue from "../../utils/getPreviousValue";

export function setSponsoredAdsPageLoadValues() {
  const s = getWindow().s;
  const eVar230FromPLP = getPreviousValue("gpv_eVar230");

  if (!eVar230FromPLP) {
    return;
  }

  s.eVar230 = eVar230FromPLP;

  const eVar10FromPLP = getPreviousValue("gpv_eVar10");

  if (eVar10FromPLP) {
    s.eVar10 = eVar10FromPLP;
  }

  s.products = addMerchEVarToProducts(
    s.products,
    `eVar243=${s.getAndPersistValue(null, "gpv_eVar243")}`
  );

  setEvents({ events: ["event330"], trackingName: "pageLoad" });
}
