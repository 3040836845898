import getWindow from "../../utils/window";
import getRuntimeConstants from "../../runtimeConstants";
import { setEvents } from "../events";
import { addMerchEVarToProducts } from "../common";
import { isProductWithSupplierColours } from "../../components/facets/utils/shouldShowColour";

const getPageName = ({ isMixAndMatch, webContext }) => {
  const pageType = isMixAndMatch ? "mix&match" : "product";
  const platform = webContext.platform;
  const storeId = webContext.storeId.toLowerCase();

  return `${platform} ${storeId}|${pageType}|${getWindow().document.title}`;
};

const getProductIds = ({ isMixAndMatch, product }) =>
  isMixAndMatch
    ? `;${product.products.map(({ id }) => id).join(",;")}`
    : `;${product.id}`;

const isSizeGuideVisible = ({ isMixAndMatch, product }) =>
  isMixAndMatch
    ? product.products.some(({ sizeGuideVisible }) => sizeGuideVisible)
    : product.sizeGuideVisible;

const addMixAndMatchOnlyValues = ({ events, s, isMixAndMatch }) => {
  if (isMixAndMatch) {
    s.eVar181 = "mix & match";

    events.push("event15");
  }
};

const addShippingRestrictions = ({ product, events }) => {
  if (product.shippingRestrictions?.shippingRestrictionsVisible) {
    events.push("event217");
  }
};

const addVideo = ({ product, events }) => {
  if (product.media?.catwalkUrl?.length) {
    events.push("event245");
  }
};

const addIngredients = ({ product, events }) => {
  if (product.isInStock && product.hasVariantsWithIngredients) {
    events.push("event281");
  }
};

const addSellingFast = ({ product, s }) => {
  if (product.sellingFast) {
    s.eVar152 = "selling fast";
  }
};

const addFacets = ({ product, s }) => {
  if (s?.products && isProductWithSupplierColours(product)) {
    s.products = addMerchEVarToProducts(s.products, "eVar246=more colours");
  }
};

const addSingleProductOnlyValues = ({ events, s, isMixAndMatch, product }) => {
  if (!isMixAndMatch) {
    addShippingRestrictions({ product, events });
    addVideo({ product, events });
    addIngredients({ product, events });
    addSellingFast({ product, s });
    addFacets({ product, s });
  }
};

export function setSingleAndMixAndMatchPageLoadValues({ product }) {
  const s = getWindow().s;
  const events = [];

  const { isMixAndMatch, webContext } = getRuntimeConstants();

  s.pageName = getPageName({ isMixAndMatch, webContext });
  s.products = getProductIds({ isMixAndMatch, product });

  const previousPageName = s.getPreviousValue(s.pageName, "gpv_p10", "");

  if (previousPageName && previousPageName !== "no value") {
    s.prop10 = previousPageName.toLowerCase();
  }

  if (isSizeGuideVisible({ isMixAndMatch, product })) {
    s.eVar183 = product.brandName;
    events.push("event256");
  }

  addMixAndMatchOnlyValues({ events, s, isMixAndMatch });
  addSingleProductOnlyValues({ events, s, isMixAndMatch, product });

  setEvents({ events, trackingName: "pageLoad" });
}
