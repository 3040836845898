import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import useEventBus from "../../hooks/useEventBus";
import { customerPreferenceCookie } from "../../constants";

const PrivacyContext = createContext({});

export const PrivacyContextProvider = ({ children }) => {
  const [customerPreferences, setCustomerPreferences] = useState(null);

  useEventBus("onetrust.consentChanged", (consentIds) => {
    const consentArray = consentIds.split(",");
    setCustomerPreferences(consentArray.includes(customerPreferenceCookie));
  });

  return (
    <PrivacyContext.Provider value={{ customerPreferences }}>
      {children}
    </PrivacyContext.Provider>
  );
};

export const usePrivacy = () => useContext(PrivacyContext);

PrivacyContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
