import getWindow from "../../utils/window";
import { getDiscountAmount } from "../../utils/discountPriceData";
import { hasPageLoadFired } from "./pageLoadTrackingFired";

export const setDiscountOnPrice = ({ priceData }) => {
  if (!getWindow() || hasPageLoadFired()) {
    return;
  }

  const s = getWindow().s;

  const discountAmount = getDiscountAmount(priceData);

  if (discountAmount && !s.eVar161) {
    s.eVar161 = discountAmount.toString();
  }
};
