import { useState, useCallback } from "react";
import { SMALL_VIEWPORT, MEDIUM_VIEWPORT, LARGE_VIEWPORT } from "../constants";
import useMatchMedia from "./useMatchMedia";

const SMALL_VIEWPORT_MEDIA_QUERY = "(max-width: 720px)";
const MEDIUM_VIEWPORT_MEDIA_QUERY =
  "(min-width: 721px) and (max-width: 1023px)";
const LARGE_VIEWPORT_MEDIA_QUERY = "(min-width: 1024px)";

const useLayoutState = () => {
  const [viewportCategory, setViewportCategory] = useState(SMALL_VIEWPORT);

  const smallViewportOnChangeHandler = useCallback(
    ({ matches }) => matches && setViewportCategory(SMALL_VIEWPORT),
    []
  );

  const mediumViewportOnChangeHandler = useCallback(
    ({ matches }) => matches && setViewportCategory(MEDIUM_VIEWPORT),
    []
  );

  const largeViewportOnChangeHandler = useCallback(
    ({ matches }) => matches && setViewportCategory(LARGE_VIEWPORT),
    []
  );

  useMatchMedia(SMALL_VIEWPORT_MEDIA_QUERY, smallViewportOnChangeHandler);
  useMatchMedia(MEDIUM_VIEWPORT_MEDIA_QUERY, mediumViewportOnChangeHandler);
  useMatchMedia(LARGE_VIEWPORT_MEDIA_QUERY, largeViewportOnChangeHandler);

  return viewportCategory;
};

export default useLayoutState;
