import getSdks from "./sdks";
import getWindow from "./window";
import { ERROR_TYPE, logError } from "./newRelicLogger";

const PDP_FIXED_ATTRIBUTES = {
  app: "pdp",
};

let resolveSdkPromise;
let getFeatureFromSdk = {
  getFeatures: () => ({
    isEnabled: () => false,
    getVariables: () => null,
  }),
  track: () => null,
};

const onReadyPromise = new Promise((resolve) => {
  resolveSdkPromise = resolve;
});

export const onReady = () => onReadyPromise;

export const getFeature = (key, id, attributes) => ({
  track: (featureId) => getFeatureFromSdk.track(featureId),
  ...getFeatureFromSdk.getFeatures(key, id, {
    ...PDP_FIXED_ATTRIBUTES,
    ...attributes,
  }),
});

const enableEdgeMVT = ({ isMixAndMatch, isInStock, isEnabled }) => {
  if (isMixAndMatch === false && isInStock === false) {
    isEnabled();
  }
};

export const initialiseFeatures = (isInStock, isMixAndMatch) => {
  getSdks()?.features.then(async (featureSdk) => {
    const {
      getFeature: featuresSdkGetFeature,
      onReady: featuresSdkOnReady,
      track: featuresSdkTrack,
    } = featureSdk;
    await featuresSdkOnReady();
    getFeatureFromSdk = {
      getFeatures: featuresSdkGetFeature,
      track: featuresSdkTrack,
    };
    resolveSdkPromise(); // this resolution must happen last to avoid race conditions
  });

  onReady().then(() => {
    if (getWindow().asos.pdp.config.edgeFeature) {
      const { featureId, bucket: serverBucket } =
        getWindow().asos.pdp.config.edgeFeature;

      const { isEnabled, getVariables } = getFeature(featureId);

      const clientBucket = getVariables()?.bucket;
      if (clientBucket !== serverBucket) {
        logError(ERROR_TYPE.EdgeError, {
          featureId,
          serverBucket,
          clientBucket,
          action: "edgeMVTVariables",
          message: `Server bucket [${serverBucket}] does not match bucket returned on the client [${clientBucket}]`,
        });
      } else {
        enableEdgeMVT({ isMixAndMatch, isInStock, isEnabled });
      }
    }
  });
};
