import { useStockPrice } from "../context/stockPrice/StockPriceContext";
import { initialiseAnalytics } from "../analytics/pageLoad";
import { useFeaturesReady } from "../context/featuresReady/FeaturesReadyContext";
import { useRuntimeConstants } from "../context/runtimeConstants/RuntimeConstantsContext";
import { useEffect } from "react";

export default function useAnalytics() {
  const { products } = useStockPrice();
  const { areFeaturesReady } = useFeaturesReady();
  const { ratings } = useRuntimeConstants();

  useEffect(() => {
    initialiseAnalytics({ product: products, areFeaturesReady, ratings });
  }, [products, areFeaturesReady, ratings]);
}
