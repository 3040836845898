import { getVariantForFacet } from "../context/variant/facetVariants";
import { getVariantFromUrl } from "./url";
import { getInStockVariants } from "../../utils/variantUtils";

export const getPrimaryImage = (images) => {
  const primary = images.find((image) => image.isPrimary);
  return primary || images[0];
};

export const getPrimaryImageName = (images) => {
  return getPrimaryImage(images)?.url.split("/").pop();
};

export const getPrimaryVariant = (variants) => {
  const primary = variants.find((variant) => variant.isPrimary);
  return primary || variants[0];
};

export const getVariantImage = (images, variant) => {
  return images.find((image) => image.colourWayId === variant.colourWayId);
};

export const productHasMultipleColours = (product) =>
  product?.totalNumberOfColours > 1;

export const productHasMultipleSizes = (product) =>
  !!(product && !(product?.isOneSize || product?.isNoSize));

export const productHasMultipleVariants = (product) =>
  product?.variants?.length > 1;

export const shouldDisplayVariantSelectBasedOnVariants = (
  product,
  oosVariantsAreSelectable
) => {
  if (!product?.variants) {
    return false;
  }

  if (!oosVariantsAreSelectable && productHasMultipleColours(product)) {
    return getInStockVariants(product.variants).length > 1;
  }

  return productHasMultipleVariants(product);
};

export const productIsInStock = (product) =>
  product?.variants.some((variant) => variant.isInStock);

export const getPreselectedVariant = (
  product,
  previousBackInStockVariantId
) => {
  const variantFromNotificationLoginFlow = product?.variants?.find(
    ({ variantId }) => variantId === previousBackInStockVariantId
  );

  if (variantFromNotificationLoginFlow) {
    return variantFromNotificationLoginFlow;
  }

  const variantFromUrl = getVariantFromUrl(product);

  if (variantFromUrl) {
    return variantFromUrl;
  }

  const variantFromFacetSelection = getVariantForFacet(product);

  if (variantFromFacetSelection) {
    return variantFromFacetSelection;
  }
};

export const getFirstVariant = (product) => {
  if (!productHasMultipleVariants(product) && product?.variants?.length) {
    return product.variants[0];
  }
  return null;
};

export const getPreselectedProduct = (
  products,
  previousBackInStockProductId
) => {
  return products?.find(({ id }) => id === previousBackInStockProductId);
};

export const getOOSProducts = (products) =>
  products.filter((product) => !product.isInStock);
