import getWindow from "./window";

let sdks;

function getSdk(sdkName, callback) {
  sdks[sdkName] = new Promise(function (resolve) {
    const resolveSdk = () => {
      if (callback) {
        callback(getWindow().asos.sdk[sdkName]);
      }
      resolve(getWindow().asos.sdk[sdkName]);
    };
    if (getWindow().asos.sdk && getWindow().asos.sdk[sdkName]) {
      resolveSdk();
    } else {
      getWindow().asos.eventBus.once(`${sdkName}-sdk-ready`, resolveSdk);
    }
  });
}

export default function getSdks() {
  if (getWindow() && !sdks) {
    sdks = {};
    getSdk("identity");
    getSdk("bag");
    getSdk("savedItems");
    getSdk("features");
  }

  return sdks;
}
