import { createContext, useState, useContext } from "react";
import { PropTypes } from "prop-types";

const SaveForLaterContext = createContext(null);

export const SaveForLaterProvider = ({ defaultState, children }) => {
  const [saveForLaterState, setSaveForLaterState] = useState(
    defaultState || {}
  );

  const saveForLater = (variantIdentifier) =>
    setSaveForLaterState({ ...saveForLaterState, [variantIdentifier]: true });

  const isSavedForLater = (variantIdentifier) =>
    !!saveForLaterState[variantIdentifier];

  return (
    <SaveForLaterContext.Provider value={{ isSavedForLater, saveForLater }}>
      {children}
    </SaveForLaterContext.Provider>
  );
};

SaveForLaterProvider.propTypes = {
  defaultState: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useSaveForLater = () => useContext(SaveForLaterContext);
