import { createContext, useContext, useReducer } from "react";
import { PropTypes } from "prop-types";
import { usePriceAndProductData } from "./usePriceAndProductData";

export const StockPriceContext = createContext({});

export const StockPriceProvider = ({ children }) => {
  const [retrying, retryStockPriceCall] = useReducer(
    (state, action) => action,
    false
  );

  const { products, hasError } = usePriceAndProductData({
    retry: retrying,
    retryStockPriceCall,
  });

  return (
    <StockPriceContext.Provider
      value={{ products, shouldRetry: hasError, retryStockPriceCall }}
    >
      {children}
    </StockPriceContext.Provider>
  );
};

StockPriceProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useStockPrice = () => useContext(StockPriceContext);
