export const getBreadcrumbsForAnalytics = ({
  breadcrumbsData,
  defaultAnalyticsBreadcrumbs,
  referrerData,
}) => {
  if (!breadcrumbsData) {
    return;
  }

  const homeLabel = breadcrumbsData[0].Label;

  if (referrerData?.searchQuery && !referrerData?.cid) {
    return `${homeLabel}//${referrerData.searchQuery}`;
  }

  if (referrerData?.cid) {
    return defaultAnalyticsBreadcrumbs;
  }

  return undefined;
};
