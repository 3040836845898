import { useEffect } from "react";
import useFeatures from "./hooks/useFeatures";
import useAnalytics from "./hooks/useAnalytics";
import { postPageLoad } from "./analytics/postPageLoad/postPageLoad";
import { useRuntimeConstants } from "./context/runtimeConstants/RuntimeConstantsContext";
import { MixAndMatchPage, SingleProductPage } from "./pages";
import useSetNotificationsOnReturnFromLogin from "./hooks/useLoginReturn";

export default function CriticalApp() {
  const { isMixAndMatch } = useRuntimeConstants();

  useSetNotificationsOnReturnFromLogin();
  useFeatures();
  useAnalytics();

  useEffect(() => {
    postPageLoad();
  }, []);

  return isMixAndMatch ? <MixAndMatchPage /> : <SingleProductPage />;
}
