import getSdks from "./sdks";
import { logError, ERROR_TYPE } from "./newRelicLogger";

export async function getAuthorisationHeader() {
  const BOT_AUTH_HEADER = "Bearer 11111111111111111111111111111111";
  const IDENTITY_V2_TIMEOUT_MS = 10_000;
  let getAuthHeader, isAuthHeaderCached, isAsosAuthStorageError;

  try {
    ({ getAuthHeader, isAuthHeaderCached, isAsosAuthStorageError } =
      await getSdks().identity);

    if (!isAuthHeaderCached()) {
      return BOT_AUTH_HEADER;
    }
    return await getAuthHeader({
      timeoutMs: IDENTITY_V2_TIMEOUT_MS,
      retries: 0,
    });
  } catch (error) {
    if (!isAsosAuthStorageError(error)) {
      logError(ERROR_TYPE.SdkError, {
        sdk: "identity",
        action: "getAuthorisationHeader",
        message: error.message,
      });
    }
    return BOT_AUTH_HEADER;
  }
}

export async function getCustomerId() {
  const { customer } = await getSdks().identity;
  return customer.customerId();
}

export async function getCustomerIsAuthenticated() {
  const { customer } = await getSdks().identity;
  return customer.isAuthenticated();
}

export async function loginRedirect() {
  const identitySdk = await getSdks().identity;
  identitySdk.login();
}
