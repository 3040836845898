function getCurrentPricePrefix({ translations, showFromLabel, showNowLabel }) {
  if (showFromLabel) {
    return `${translations("pdp_misc_from")} `;
  }
  if (showNowLabel) {
    return `${translations("now_pricing_label")} `;
  }
  return "";
}

export function buildCurrentPrice({
  current,
  translations,
  showFromLabel,
  showNowLabel,
}) {
  if (!current?.text) {
    return "";
  }

  return `${getCurrentPricePrefix({
    translations,
    showFromLabel,
    showNowLabel,
  })}${current.text}`;
}

export function buildCurrentAriaLabel({
  current,
  translations,
  showFromLabel,
  showNowLabel,
}) {
  if (!current?.text) {
    return "";
  }

  return `${getCurrentPricePrefix({
    translations,
    showFromLabel,
    showNowLabel,
  })}${current.text}`;
}
