import eventBusEmitter from "./eventBusEmitter";
import getWindow from "./window";

export const getLocalStorageItem = (key) => getStorage("localStorage", key);
export const removeLocalStorageItem = (key) =>
  removeStorage("localStorage", key);
export const setLocalStorageItem = (key, value) =>
  setStorage("localStorage", key, value);

export const getSessionStorageItem = (key) => getStorage("sessionStorage", key);
export const removeSessionStorageItem = (key) =>
  removeStorage("sessionStorage", key);
export const setSessionStorageItem = (key, value) =>
  setStorage("sessionStorage", key, value);

function setStorage(storageType, key, value) {
  try {
    const valueToStore =
      typeof value === "object" && value ? JSON.stringify(value) : value;

    getWindow()[storageType].setItem(key, valueToStore);
    eventBusEmitter(buildStorageEventName(storageType, key), value);
  } catch (error) {
    // no-op
  }
}

function getStorage(storageType, key) {
  let item = null;

  try {
    item = getWindow()[storageType].getItem(key);

    if (item === "undefined") {
      return undefined;
    }

    return JSON.parse(item);
  } catch (error) {
    return item;
  }
}

function removeStorage(storageType, key) {
  getWindow()[storageType].removeItem(key);
  eventBusEmitter(buildStorageEventName(storageType, key), undefined);
}

function buildStorageEventName(storageType, key) {
  return `${storageType}:${key}`;
}

export function buildSessionStorageEventName(key) {
  return buildStorageEventName("sessionStorage", key);
}
